import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    Link as MLink,
    LinearProgress,
    Typography,
    ButtonGroup,
    Tooltip,
    IconButton,
    Grid,
    Button,
    makeStyles,
    Drawer,
    TextField
} from '@material-ui/core'
import axios from 'axios'
import { URL } from '../config'
import { useSnackbar } from 'notistack'
import TuneIcon from '@material-ui/icons/Tune'
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

import TableLocal from '../components/TableLocal'
import { Link } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
    filter: {
        padding: theme.spacing(4),
        width: '450px',
    },
}));

const Reports = () => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const token = localStorage.getItem('kpToken')
    const [loading, setLoading] = useState(false)
    const [showClear, setShowClear] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    const { enqueueSnackbar } = useSnackbar()
    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }
    const [stores, setStores] = useState([])
    const [store, setStore] = useState({})
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState({})
    const [statuses, setStatuses] = useState([])
    const [status, setStatus] = useState({})
    const [tableDataSummary, setTableDataSummary] = useState([
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
        {
            categoryName: 'Category Name',
            activeCoupons: 12,
            inactiveCoupons: 12,
            redeemedCoupon: 12,
            totalDiscountRedeemed: '₹99,999',
        },
    ])

    const [tableDataCoupons, setTableDataCoupons] = useState([
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
        {
            couponName: 'Offer Name',
            storeDetails: 'Store Name',
            couponCategory: 'Category Name',
            totalRedeemed: 12,
            couponStartDate: '22 June 2020',
            couponEndDate: '27 June 2020',
            currentStatus: 'Active',
            displayImage: 12,
            action: <ButtonGroup size="small" aria-label="small outlined button group">
                <Tooltip title="View">
                    <IconButton>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>,
        },
    ])

    const columnsSummary = [
        {
            name: "#",
            label: "Sr No",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return rowIndex + 1
                }
            }
        },
        {
            name: "categoryName",
            label: "Category Name",
            options: {
                sort: true,
            }
        },
        {
            name: "activeCoupons",
            label: "Active Coupons",
            options: {
                sort: true,
            }
        },
        {
            name: "inactiveCoupons",
            label: "Inactive Coupons",
            options: {
                sort: true,
            }
        },
        {
            name: "redeemedCoupon",
            label: "Redeemed Coupon",
            options: {
                sort: true,
            }
        },
        {
            name: "totalDiscountRedeemed",
            label: "Total Discount Redeemed",
            options: {
                sort: true,
            }
        }
    ]

    const columnsCoupons = [
        {
            name: "#",
            label: "Sr No",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return rowIndex + 1
                }
            }
        },
        {
            name: "couponName",
            label: "Coupon Name",
            options: {
                sort: true,
            }
        },
        {
            name: "storeDetails",
            label: "Store Details",
            options: {
                sort: true,
            }
        },
        {
            name: "couponCategory",
            label: "Coupon Category",
            options: {
                sort: true,
            }
        },
        {
            name: "totalRedeemed",
            label: "Total Redeemed",
            options: {
                sort: true,
            }
        },
        {
            name: "couponStartDate",
            label: "Coupon Start Date",
            options: {
                sort: true,
            }
        },
        {
            name: "couponEndDate",
            label: "Coupon End Date",
            options: {
                sort: true,
            }
        },
        {
            name: "currentStatus",
            label: "Current Status",
            options: {
                sort: true,
            }
        },
        {
            name: "displayImage",
            label: "Display Image",
            options: {
                sort: true,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    // const getCouponsSummary = () => {
    //     setLoading(true)
    //     const headerConfig = {
    //         headers: {
    //             'accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${token}`
    //         }
    //     }
    //     const url = URL + '/API_HERE'
    //     axios.get(url, headerConfig).then(response => {
    //         setTableData(response.data)
    //         setLoading(false)
    //     }).catch(error => {
    //         let message = 'Try after some time.'
    //         if (error.response) {
    //             (process.env.NODE_ENV !== 'production') && console.log(error.response)
    //         } else if (error.request) {
    //             (process.env.NODE_ENV !== 'production') && console.log(error.request)
    //         } else {
    //             (process.env.NODE_ENV !== 'production') && console.log(error)
    //         }
    //         sendNotification(message, 'error')
    //         setLoading(false)
    //     })
    // }

    // useEffect(() => {
    //     getCouponsSummary()
    //     // eslint-disable-next-line
    // }, [])

    const toggleDrawer = () => {
        setOpen(!open)
    }

    const clearFilters = () => {
        toggleDrawer()
        setShowClear(false)
        setStore(null)
    }

    const clearButton = showClear ? (
        <>
            &nbsp;
            &nbsp;
            <Button variant="contained" color="secondary" onClick={clearFilters}>
                Clear
            </Button>
        </>
    ) : ''

    const handleStoreChange = (e, item) => {
        if (item !== null && showClear === false) {
            setShowClear(true)
        }
        setStore(item)
    }
    const handleCategoryChange = (e, item) => {
        if (item !== null && showClear === false) {
            setShowClear(true)
        }
        setCategory(item)
    }
    const handleStatusChange = (e, item) => {
        if (item !== null && showClear === false) {
            setShowClear(true)
        }
        setStatus(item)
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <Typography color="textPrimary">Reports</Typography>
            </Breadcrumbs>
            <br />
            {progress}
            <TableLocal
                downloadCSV={true}
                title={'Coupons Summary'}
                columns={columnsSummary}
                data={tableDataSummary}
            />
            <br /><br />
            <Grid container justify="space-between">
                <Grid item>
                    <Typography variant="h6" color="textPrimary">
                        All Coupons
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={toggleDrawer}><TuneIcon />&nbsp;Filters</Button>
                </Grid>
            </Grid><br />
            <TableLocal
                downloadCSV={true}
                columns={columnsCoupons}
                data={tableDataCoupons}
            />
            <Drawer anchor='right' open={open} onClose={toggleDrawer}>
                <div className={classes.filter}>
                    <Grid container
                        direction="column"
                        spacing={2}
                    >
                        <Grid item >
                            <Grid container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-start"
                            >
                                <Grid item >
                                    <Tooltip title="Close"><IconButton onClick={toggleDrawer}><CloseIcon /></IconButton></Tooltip>
                                    &nbsp;
                                    <Button variant="contained" color="primary" onClick={() => {
                                        toggleDrawer()
                                    }}>
                                        Apply
                                    </Button>
                                    {clearButton}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={stores}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                value={store}
                                renderInput={(params) => <TextField {...params} variant="outlined" label="Store" />}
                                onChange={handleStoreChange}
                            /> <br />
                            <Autocomplete
                                options={categories}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                value={category}
                                renderInput={(params) => <TextField {...params} variant="outlined" label="Category" />}
                                onChange={handleCategoryChange}
                            /><br />
                            <Autocomplete
                                options={statuses}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                value={status}
                                renderInput={(params) => <TextField {...params} variant="outlined" label="Status" />}
                                onChange={handleStatusChange}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Drawer>
        </div>
    )
}

export default Reports
