import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Link as MLink,
    Typography,
    Chip,
    Avatar
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../config'
import { timestampToDateString } from '../../functions'

const ViewVendor = (props) => {
    const { id } = props.match.params
    const token = localStorage.getItem('kpToken')

    const [vendor, setVendor] = useState({})

    const getVendorDetails = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/vendorUser/' + id
        axios.get(url, headerConfig).then(res => {
            setVendor(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getVendorDetails()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/vendors">
                    Vendors
                </MLink>
                <Typography color="textPrimary">View Vendor</Typography>
            </Breadcrumbs>
            <br />
            <Card>
                <CardContent>
                    <Typography variant="h5" color="initial">Vendor Details</Typography>
                    <br />
                    <table className="details">
                        <tr>
                            <th>Full Name</th>
                            <td>{vendor.fullName}</td>
                            <th>ID</th>
                            <td>{vendor.id}</td>
                        </tr>
                        <tr>
                            <th>Mobile</th>
                            <td><MLink href={'tel:' + vendor.mobile}>{vendor.mobile}</MLink></td>
                            <th>Email</th>
                            <td><MLink href={'mailto:' + vendor.email}>{vendor.email}</MLink></td>
                        </tr>
                        <tr>
                            <th>UUID</th>
                            <td>{vendor.uuid}</td>
                            <th>Image</th>
                            <td><Avatar src={URL + '/downloadFile/' + vendor.imageName} alt="profile" /> </td>
                        </tr>
                        <tr>
                            <th>City</th>
                            <td>{vendor.cityName}</td>
                            <th>Is Verified</th>
                            <td>{vendor.isVerified ? <Chip label="True" /> : <Chip label="False" />}</td>
                        </tr>
                        <tr>
                            <th>Created At</th>
                            <td>{timestampToDateString(vendor.createdAt)}</td>
                            <th>Updated At</th>
                            <td>{timestampToDateString(vendor.updatedAt)}</td>
                        </tr>
                    </table>
                    <br />
                    <Link to="/dashboard/vendors"><Button variant="contained">Go to Back</Button></Link>
                </CardContent>
            </Card>
        </div>
    )
}

export default ViewVendor
