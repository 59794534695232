import React, { useEffect, useState, useCallback } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slider } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../config'
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack'
// Cropper
import Cropper from 'react-easy-crop'
import { DropzoneArea } from 'material-ui-dropzone'
import { getCroppedImg, getRotatedImage } from '../../canvasUtils'
import { getOrientation } from 'get-orientation/browser'
import { dataURLtoFile, trim } from '../../functions';
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles';

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

const useStyles = makeStyles((theme) => ({
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
            height: 400,
        },
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    controls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    sliderLabel: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 65,
        },
    },
    slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },
    },
    paper: { minWidth: "1000px" },
}));

const UpdateKoopan = (props) => {
    const location = useLocation()
    const title = location.pathname.includes('approved-koopan') ? 'Approved Koopan' : 'List Pending Koopan'
    const path = location.pathname.includes('approved-koopan') ? '/dashboard/approved-koopan' : '/dashboard/list-pending-koopan'
    const classes = useStyles();
    const { id } = props.match.params
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState('');
    const [disabled, setDisabled] = useState(false);
    const token = localStorage.getItem('kpToken')
    const _user = JSON.parse(localStorage.getItem('kpUser'))
    const user = (_user === null) ? {} : _user
    const progress = loading ? (<LinearProgress />) : ('')

    // Form
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(null)
    const [offerName, setOfferName] = useState('')
    const [actualPrice, setActualPrice] = useState('')
    const [offerPrice, setOfferPrice] = useState('')
    const [description, setDescription] = useState('')

    const [featuredImage, setFeaturedImage] = useState('')
    const [isMediaUpdated, setIsMediaUpdated] = useState(false)
    const [koopanData, setKoopanData] = useState({})

    // Cropper
    const [fileOpen, setFileOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [uploadImage, setUploadImage] = useState(null)
    const [mainImage, setMainImage] = useState('')

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    // Cropping Process
    const handleSelectFileOpen = (itemId) => {
        setFileOpen(true)
    }
    const handleSelectFileClose = () => {
        setFileOpen(false)
        setImageSrc(null)
    }
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])
    const imageChange = async (files) => {
        if (files && files.length > 0) {
            const file = files[0]
            let imageDataUrl = await readFile(file)
            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }
            setImageSrc(imageDataUrl)
        } else {
            setImageSrc(null)
        }
    }
    const updateImage = async () => {
        setLoading(true)
        setDisabled(true)
        const croppedImageBase64 = await getCroppedImg(
            imageSrc,
            croppedAreaPixels
        )
        const name = uuidv4() + '.png'
        var file = dataURLtoFile(croppedImageBase64, name);
        setUploadImage(file)
        setMainImage(croppedImageBase64)
        setIsMediaUpdated(true)
        handleSelectFileClose()
        setDisabled(false)
        setLoading('')
    }

    const getCategories = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listCategory'
        axios.get(url, headerConfig).then(response => {
            setCategories(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getCategories()
        getData()
        // eslint-disable-next-line
    }, [])

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/vendorKoopan/' + id
        axios.get(url, headerConfig).then(res => {
            const data = res.data
            const category = {
                id: data.categoryId,
                categoryName: data.categoryName
            }
            setCategory(category)
            setOfferName(data.offerName)
            setActualPrice(data.actualPrice)
            setOfferPrice(data.offerPrice)
            setDescription(data.description)
            setFeaturedImage(data.bannerName)
            setKoopanData(data)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = koopanData
        data.offerName = trim(offerName)
        data.categoryId = category.id
        data.actualPrice = parseInt(actualPrice)
        data.offerPrice = parseInt(offerPrice)
        data.description = trim(description)
        data.updatedBy = user.userId

        if (isMediaUpdated) {
            var bodyFormData = new FormData();
            bodyFormData.append('file', uploadImage);
            const url = URL + '/uploadFile'
            await axios.post(url, bodyFormData).then(response => {
                data.bannerId = response.data.id
            }).catch(error => {
                console.log(error);
            })
        }

        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/updateVendorKoopan'
        axios.post(url, data, headerConfig).then(response => {
            const message = 'Koopan updated.'
            sendNotification(message)
            setLoading(false)
            props.history.push(path)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 400) {
                    message = error.response.data.message
                } else if (error.response.status === 401) {
                    message = error.response.data.error
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
            setLoading(false)
        })
    }

    const handleCategoryChange = (e, item) => {
        setCategory(item)
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href={'/#' + path}>
                    {title}
                </MLink>
                <Typography color="textPrimary">Update Koopan</Typography>
            </Breadcrumbs>
            <br />
            <Dialog
                classes={{ paper: classes.paper }}
                open={fileOpen}
                onClose={handleSelectFileClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {loading}
                <DialogTitle id="alert-dialog-title">Upload Image</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {imageSrc ? (
                            <div>
                                <div className={classes.cropContainer}>
                                    <Cropper
                                        image={imageSrc}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={4 / 3}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                </div>
                                <div className={classes.controls}>
                                    <div className={classes.sliderContainer}>
                                        <Typography
                                            variant="overline"
                                            classes={{ root: classes.sliderLabel }}
                                        >
                                            Zoom
                                        </Typography>
                                        <Slider
                                            value={zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aria-labelledby="Zoom"
                                            classes={{ root: classes.slider }}
                                            onChange={(e, zoom) => setZoom(zoom)}
                                        />
                                    </div>
                                    <Button onClick={handleSelectFileClose} variant="contained" autoFocus>
                                        Close
                                    </Button>
                                    <Button variant="contained" color="warning" className="ml-2" onClick={updateImage} disabled={disabled}>
                                        Update
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <DropzoneArea
                                filesLimit={1}
                                dropzoneText="Drag and drop an image here or click"
                                showAlerts={['error']}
                                acceptedFiles={['image/*']}
                                onChange={imageChange}
                            />
                        )}
                    </DialogContentText>
                </DialogContent>
                {imageSrc ? ('') : (
                    <DialogActions>
                        <Button onClick={handleSelectFileClose} variant="contained" autoFocus>
                            Close
                        </Button>
                        <Button variant="contained" color="warning" className="ml-2">
                            Update
                        </Button>
                    </DialogActions>)}
            </Dialog>
            {progress}
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Offer Name"
                                    variant="outlined"
                                    inputProps={{ minLength: 2, maxLength: 250 }}
                                    onChange={e => setOfferName(e.target.value)}
                                    value={offerName}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    label="Description"
                                    variant="outlined"
                                    inputProps={{ minLength: 2, maxLength: 300 }}
                                    onChange={e => setDescription(e.target.value)}
                                    value={description}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={categories}
                                    autoHighlight
                                    getOptionLabel={(option) => option.categoryName}
                                    value={category}
                                    renderInput={(params) => <TextField {...params} label="Category" required variant="outlined" />}
                                    onChange={handleCategoryChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Actual Price"
                                    variant="outlined"
                                    type="Number"
                                    inputProps={{ maxLength: 150, min: 0 }}
                                    onChange={e => setActualPrice(e.target.value)}
                                    value={actualPrice}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Offer Price"
                                    variant="outlined"
                                    type="Number"
                                    inputProps={{ maxLength: 150, min: 0 }}
                                    onChange={e => setOfferPrice(e.target.value)}
                                    value={offerPrice}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button variant="contained" color="primary" onClick={handleSelectFileOpen}>
                                    Change Banner Image*
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                {isMediaUpdated ? (<img src={mainImage} alt="" style={{ height: "100px" }} />) : (<img src={URL + '/downloadFile/' + featuredImage} alt="" style={{ height: "100px" }} />)}
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Link to={path}><Button variant="contained" disabled={loading}>Cancel</Button></Link>
                                <Button variant="contained" color="primary" className="ml-2" type="submit" disabled={loading}>
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default UpdateKoopan
