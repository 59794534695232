import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    Grid,
    Link as MLink,
    LinearProgress,
    Typography
} from '@material-ui/core'
import axios from 'axios'
import { URL } from '../../config'

import TableLocal from '../../components/TableLocal'

const ListAppUsers = (props) => {
    const token = localStorage.getItem('kpToken')
    const [tableData, setTableData] = useState([])

    const [loading, setLoading] = useState(false)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return rowIndex + 1
                }
            }
        },
        {
            name: "fullName",
            label: "Full Name",
            options: {
                sort: true,
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                sort: true,
            }
        },
        {
            name: "mobile",
            label: "Mobile Number",
            options: {
                sort: true,
            }
        },
    ]

    const getList = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listAppUser'
        axios.get(url, headerConfig).then(response => {
            setTableData(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">App Users</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            {progress}
            <TableLocal
                title={'List App Users'}
                columns={columns}
                data={tableData}
            />
        </div>
    )
}

export default ListAppUsers
