import React, { useState, useEffect } from 'react'
import {
    Avatar,
    Breadcrumbs,
    ButtonGroup, Button, IconButton,
    Tooltip,
    Grid,
    Link as MLink,
    LinearProgress,
    Typography
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../config'
import AddIcon from '@material-ui/icons/Add'
import { timestampToDateString } from '../../functions'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack'

import TableLocal from '../../components/TableLocal'

const ListSubscription = () => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('kpToken')
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    const [deleteModel, setDeleteModel] = useState(false);
    const [tableData, setTableData] = useState([])

    const [item, setItem] = useState(null)

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    const columns = [
        {
            name: "#",
            label: "Sr No",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return rowIndex + 1
                }
            }
        },
        {
            name: "packageName",
            label: "Name",
            options: {
                sort: true,
            }
        },
        {
            name: "packageType",
            label: "Type",
            options: {
                sort: true,
            }
        },
        {
            name: "packagePrice",
            label: "Price",
            options: {
                sort: true,
            }
        },
        {
            name: "noOfDays",
            label: "No of Days",
            options: {
                sort: true,
            }
        },
        {
            name: "noOfKoopan",
            label: "No of Koopan",
            options: {
                sort: true,
            }
        },
        {
            name: "rewardPoint",
            label: "Reward Point",
            options: {
                sort: true,
            }
        },
        {
            name: "redeemPoint",
            label: "Redeem Point",
            options: {
                sort: true,
            }
        },
        {
            name: "packageId",
            label: "Product Id",
            options: {
                sort: true,
            }
        },
        {
            name: "createdAt",
            label: "Created On",
            options: {
                sort: true,
                customBodyRender: timestampToDateString
            }
        },
        {
            name: "image",
            label: "Logo",
            options: {
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listActiveSubscriptionPackages'
        axios.get(url, headerConfig).then(response => {
            response.data.map(item => {
                const action = (
                    <ButtonGroup size="small" aria-label="small outlined button group">
                        <Link to={'/dashboard/subscription/' + item.id}>
                            <Tooltip title="Edit">
                                <IconButton>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Tooltip title="Delete">
                            <IconButton onClick={() => handleModelOpen(item)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                )
                item.action = action

                const image = (
                    <Avatar src={URL + '/downloadFile/' + item.packageLogoName} alt="Package logo" className="ml-auto mr-auto" />
                )
                item.image = image
                return true
            })
            setTableData(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const handleModelOpen = (item) => {
        setItem(item)
        setDeleteModel(true);
    };

    const handleModalClose = () => {
        setItem(null)
        setDeleteModel(false);
    };

    // Delete Category
    const deleteItem = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/inactiveSubscriptionPackage'
        axios.post(url, item, headerConfig).then(response => {
            let message = 'Deleted successfully'
            sendNotification(message)
            handleModalClose()
            getList()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 400) {
                    message = error.response.data.message
                } else if (error.response.status === 401) {
                    message = error.response.data.error
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
    }

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Subscription Packages</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item >
                    <Link to="subscription/add"><Button variant="contained" color="primary" startIcon={<AddIcon />}>Add Package</Button></Link>
                </Grid>
            </Grid>
            <br />
            {progress}
            <TableLocal
                title={'Subscription Packages'}
                columns={columns}
                data={tableData}
            />
            <Dialog
                open={deleteModel}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this Subscription Package?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteItem} color="primary" autoFocus>
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ListSubscription
