import React, { useState, useEffect } from 'react'
import {
    Avatar,
    Breadcrumbs,
    ButtonGroup,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
    Grid,
    Link as MLink,
    LinearProgress,
    Typography,
    Chip
} from '@material-ui/core'
import axios from 'axios'
import { URL } from '../../config'
import { timestampToDateString } from '../../functions'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { useSnackbar } from 'notistack'
import { Link } from 'react-router-dom'

import TableLocal from '../../components/TableLocal'

const ListPendingStores = () => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('kpToken')
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    const [updateModel, setUpdateModel] = useState(false);
    const [tableData, setTableData] = useState([])
    const [deleteModel, setDeleteModel] = useState(false);
    const [id, setId] = useState(null)

    const [storeDetails, setStoreDetails] = useState({})

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    const columns = [
        {
            name: "storeName",
            label: "Store Details",
            options: {
                sort: true
            }
        },
        {
            name: "categoryName",
            label: "Store Category",
            options: {
                sort: true,
            }
        },
        {
            name: "storeMobile",
            label: "Mobile Number",
            options: {
                sort: true,
            }
        },
        {
            name: "storeEmailId",
            label: "Email ID",
            options: {
                sort: true,
            }
        },
        {
            name: "createdAt",
            label: "Requested On",
            options: {
                sort: true,
                customBodyRender: timestampToDateString
            }
        },
        {
            name: "storeIcon",
            label: "Display Image",
            options: {
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getPendingStores = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listAllPendingStore'
        axios.get(url, headerConfig).then(response => {
            response.data.map(item => {
                const action = (
                    <ButtonGroup size="small" aria-label="small outlined button group">
                        <Tooltip title="Update Status">
                            <IconButton onClick={() => handleUpdateStatus(item.id)}>
                                <AssignmentTurnedInIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Link to={'/dashboard/list-pending-stores/' + item.id}>
                            <Tooltip title="Edit">
                                <IconButton>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Tooltip title="Delete">
                            <IconButton onClick={() => handleModelOpen(item.id)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                )
                item.action = action

                const storeIcon = (
                    <Avatar src={URL + '/downloadFile/' + item.logoName} alt="logo" className="ml-auto mr-auto" />
                )
                item.storeIcon = storeIcon

                const storeEmailId = (
                    <MLink href={'mailto:' + item.storeEmailId}>{item.storeEmailId}</MLink>
                )
                item.storeEmailId = storeEmailId
                const storeMobile = (
                    <MLink href={'tel:' + item.storeMobile}>{item.storeMobile}</MLink>
                )
                item.storeMobile = storeMobile
                return true
            })
            setTableData(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getPendingStores()
        // eslint-disable-next-line
    }, [])

    const handleUpdateStatus = (updateId) => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/vendorStore/' + updateId
        axios.get(url, headerConfig).then(response => {
            setStoreDetails(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
        })
        setUpdateModel(true);
    };

    const handleModelOpen = (deleteId) => {
        setId(deleteId)
        setDeleteModel(true);
    };

    const handleModalClose = () => {
        setUpdateModel(false);
        setId(null)
        setDeleteModel(false);
    };

    // Approve Request
    const handleApproveRequest = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const data = storeDetails
        data.storeStatus = "APPROVED"
        const url = URL + '/updateVendorStoreStatus/'
        axios.post(url, data, headerConfig).then(response => {
            sendNotification('Store Request Approved')
            handleModalClose()
            getPendingStores()
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
            sendNotification(message, 'error')
        })
    }

    // Delete Request
    const handleDeleteRequest = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const data = storeDetails
        data.storeStatus = "DENIED"
        const url = URL + '/updateVendorStoreStatus/'
        axios.post(url, data, headerConfig).then(response => {
            let message = 'Store Request Denied'
            sendNotification(message)
            handleModalClose()
            getPendingStores()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 400) {
                    message = error.response.data.message
                } else if (error.response.status === 401) {
                    message = error.response.data.error
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
    }

    const deleteItem = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/deleteVendorStore/' + id
        axios.delete(url, headerConfig).then(response => {
            let message = 'Deleted successfully'
            sendNotification(message)
            handleModalClose()
            getPendingStores()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 500) {
                    message = 'Store can not be deleted as it has associate data present.'
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
    }

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">List Pending Stores</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            {progress}
            <TableLocal
                title={'List Pending Stores'}
                columns={columns}
                data={tableData}
            />
            <Dialog fullWidth maxWidth="lg" onClose={handleModalClose} aria-labelledby="customized-dialog-title" open={updateModel}>
                <DialogTitle id="customized-dialog-title" onClose={handleModalClose}>
                    {storeDetails.storeName}
                </DialogTitle>
                <DialogContent dividers>
                    <table className="details">
                        <tr>
                            <th>ID</th>
                            <td>{storeDetails.id}</td>
                            <th>Logo</th>
                            <td><Avatar src={URL + '/downloadFile/' + storeDetails.logoName} alt="profile" /></td>
                        </tr>
                        <tr>
                            <th>Mobile</th>
                            <td><MLink href={'tel:' + storeDetails.storeMobile}>{storeDetails.storeMobile}</MLink></td>
                            <th>Email</th>
                            <td><MLink href={'mailto:' + storeDetails.storeEmailId}>{storeDetails.storeEmailId}</MLink></td>
                        </tr>
                        <tr>
                            <th>Address</th>
                            <td>{storeDetails.storeAddress}</td>
                            <th>City</th>
                            <td>{storeDetails.cityName}</td>
                        </tr>
                        <tr>
                            <th>Longitude</th>
                            <td>{storeDetails.logi}</td>
                            <th>Latitude</th>
                            <td>{storeDetails.lati}</td>
                        </tr>
                        <tr>
                            <th>Category</th>
                            <td>{storeDetails.categoryName}</td>
                            <th>Store Status</th>
                            <td><Chip label={storeDetails.storeStatus} /></td>
                        </tr>
                        <tr>
                            <th>Created At</th>
                            <td>{timestampToDateString(storeDetails.createdAt)}</td>
                            <th>Updated At</th>
                            <td>{timestampToDateString(storeDetails.updatedAt)}</td>
                        </tr>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleApproveRequest} variant="contained" className="btn-approve">
                        Approve Request
                    </Button>
                    <Button onClick={handleDeleteRequest} variant="contained" className="ml-2 mr-2 btn-delete">
                        Deny Request
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteModel}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this Store?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteItem} color="primary" autoFocus>
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ListPendingStores
