import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";

const TableLocal = (props) => {
    const [title, setTitle] = useState('')
    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])

    useEffect(() => {
        setTitle(props.title)
        setColumns(props.columns)
        setData(props.data)
    }, [props.columns, props.data, props.title])

    const options = {
        filter: false,
        selectableRows: 'none',
        download: props.downloadCSV ? true : false,
        print: false,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 20],
    }

    return (
        <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
            <MUIDataTable
                title={title}
                data={data}
                columns={columns}
                options={options}
            />
        </div>
    );
}

export default TableLocal
