import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    ButtonGroup, Button, IconButton,
    Tooltip,
    Grid,
    Dialog, DialogTitle, DialogContent,
    Link as MLink,
    LinearProgress, DialogActions,
    TextField,
    Typography
} from '@material-ui/core'
import axios from 'axios'
import { URL } from '../config'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack'
import { Autocomplete } from '@material-ui/lab'

import TableLocal from '../components/TableLocal'

const Cities = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    const token = localStorage.getItem('kpToken')
    const _user = JSON.parse(localStorage.getItem('kpUser'))
    const user = (_user === null) ? {} : _user

    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const [cityId, setCityId] = useState(null)
    const [cityName, setCityName] = useState('')
    const [cityData, setCityData] = useState({})
    const [states, setStates] = useState([])
    const [state, setState] = useState([])

    const columns = [
        {
            name: "#",
            label: "Sr No",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return rowIndex + 1
                }
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                sort: true,
            }
        },
        {
            name: "statesId",
            label: "State ID",
            options: {
                sort: true,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listCity'
        axios.get(url, headerConfig).then(response => {
            response.data.map(item => {
                const action = (
                    <ButtonGroup size="small" aria-label="small outlined button group">
                        <Tooltip title="Edit">
                            <IconButton onClick={() => handleEditOpen(item.id)}>
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton onClick={() => handleDeleteOpen(item.id)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                )
                item.action = action
                return true
            })
            setTableData(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
        })
    }

    const handleCityChange = (e) => {
        setCityName(e.target.value)
    }

    const handleStateChange = (e, item) => {
        setState(item)
    }

    // Add City Model
    const handleAddOpen = () => {
        setOpenAdd(true);
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listStates'
        axios.get(url, headerConfig).then(response => {
            setStates(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    };
    const handleAddClose = () => {
        setOpenAdd(false);
    };
    // Submit Add Form
    const onAddSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        if (state !== [] && cityName !== '') {
            const data = {
                statesId: state.id,
                name: cityName,
                isDeleted: false,
                createdBy: user.userId,
                updatedBy: user.userId
            }
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            const url = URL + '/saveCity'
            axios.post(url, data, headerConfig).then(response => {
                const message = 'City added.'
                sendNotification(message)
                handleAddClose()
                getList()
            }).catch(error => {
                let message = 'Try after some time.'
                if (error.response) {
                    if (error.response.status === 400) {
                        message = error.response.data.message
                    } else if (error.response.status === 401) {
                        message = error.response.data.error
                    }
                    (process.env.NODE_ENV !== 'production') && console.log(error.response)
                } else if (error.request) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.request)
                } else {
                    (process.env.NODE_ENV !== 'production') && console.log(error)
                }
                sendNotification(message, 'error')
                setLoading(false)
            })
        } else {
            let message = 'Please fill required fields'
            sendNotification(message, 'error')
            setLoading(false)
        }
    }


    // Edit City Model
    const handleEditClose = () => {
        setOpenEdit(false);
    };
    // Edit City
    const handleEditOpen = (id) => {
        setLoading(true)
        getStates()
        setCityId(id)
        setOpenEdit(true);
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/city/' + id
        axios.get(url, headerConfig).then(response => {
            setCityData(response.data)
            setCityName(response.data.name)
            const urlState = URL + '/states/' + response.data.statesId
            axios.get(urlState, headerConfig).then(response => {
                setState(response.data)
            })
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 400) {
                    message = error.response.data.message
                } else if (error.response.status === 401) {
                    message = error.response.data.error
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
            setLoading(false)
        })
    };

    const getStates = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listStates'
        axios.get(url, headerConfig).then(response => {
            setStates(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }
    // Submit Edit Form
    const onEditSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            id: cityData.id,
            statesId: state.id,
            name: cityName,
            isDeleted: cityData.isDeleted,
            createdBy: cityData.createdBy,
            updatedBy: user.userId
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/updateCity'
        axios.post(url, data, headerConfig).then(response => {
            const message = 'City Updated.'
            sendNotification(message)
            getList()
            setLoading(false)
            handleEditClose()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 400) {
                    message = error.response.data.message
                } else if (error.response.status === 401) {
                    message = error.response.data.error
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
            setLoading(false)
        })
    }


    // Delete Occupation Model
    const handleDeleteOpen = (id) => {
        setCityId(id)
        setOpenDelete(true);
    };
    const handleDeleteClose = () => {
        setOpenDelete(false);
    };
    // Delete City
    const deleteItem = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const urlGet = URL + '/city/' + cityId
        axios.get(urlGet, headerConfig).then(response => {
            setCityData(response.data)
            const data = {
                id: response.data.id,
                statesId: response.data.statesId,
                name: response.data.name,
                isDeleted: true,
                createdBy: response.data.createdBy,
                updatedBy: user.userId
            }
            const urlPost = URL + '/updateCity/'
            axios.post(urlPost, data, headerConfig).then(response => {
                let message = 'Deleted successfully'
                sendNotification(message)
                handleDeleteClose()
                getList()
            }).catch(error => {
                let message = 'Try after some time.'
                if (error.response) {
                    if (error.response.status === 400) {
                        message = error.response.data.message
                    } else if (error.response.status === 401) {
                        message = error.response.data.error
                    }
                    (process.env.NODE_ENV !== 'production') && console.log(error.response)
                } else if (error.request) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.request)
                } else {
                    (process.env.NODE_ENV !== 'production') && console.log(error)
                }
                sendNotification(message, 'error')
                setLoading(false)
            })
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 400) {
                    message = error.response.data.message
                } else if (error.response.status === 401) {
                    message = error.response.data.error
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
            setLoading(false)
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Cities</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item >
                    <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddOpen}>Add City</Button>
                </Grid>
            </Grid>
            <br />
            {progress}
            <TableLocal
                title={'List Cities'}
                columns={columns}
                data={tableData}
            />
            <Dialog open={openAdd} onClose={handleAddClose} className="form-action" aria-labelledby="form-dialog-title">
                {progress}
                <DialogTitle id="form-dialog-title">Add City</DialogTitle>
                <form onSubmit={onAddSubmit}>
                    <DialogContent dividers>
                        <TextField
                            required
                            fullWidth
                            label="City Name"
                            variant="outlined"
                            inputProps={{ minLength: 3, maxLength: 150 }}
                            onChange={handleCityChange}
                        />
                        <Autocomplete
                            options={states}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            value={state}
                            className="mt-5"
                            renderInput={(params) => <TextField {...params} label="State" required variant="outlined" />}
                            onChange={handleStateChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAddClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={openEdit} onClose={handleEditClose} className="form-action" aria-labelledby="form-dialog-title">
                {progress}
                <DialogTitle id="form-dialog-title">Edit</DialogTitle>
                <form onSubmit={onEditSubmit}>
                    <DialogContent dividers>
                        <TextField
                            required
                            fullWidth
                            label="City Name"
                            value={cityName}
                            variant="outlined"
                            inputProps={{ minLength: 3, maxLength: 150 }}
                            onChange={handleCityChange}
                        />
                        <Autocomplete
                            options={states}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            value={state}
                            className="mt-5"
                            renderInput={(params) => <TextField {...params} label="State" required variant="outlined" />}
                            onChange={handleStateChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={openDelete} onClose={handleDeleteClose} className="form-action" aria-labelledby="form-dialog-title">
                {progress}
                <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Are you sure you want to delete?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(cityId)} variant="contained" color="secondary">
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Cities
