import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'

import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip
} from '@material-ui/core'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import HomeIcon from '@material-ui/icons/Home'
import StorefrontIcon from '@material-ui/icons/Storefront'
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import StoreIcon from '@material-ui/icons/Store';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ExploreIcon from '@material-ui/icons/Explore';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import SubjectIcon from '@material-ui/icons/Subject';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PeopleIcon from '@material-ui/icons/People';
import PublishIcon from '@material-ui/icons/Publish';
import StorageIcon from '@material-ui/icons/Storage';
import BookIcon from '@material-ui/icons/Book';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import ListIcon from '@material-ui/icons/List'
import PersonIcon from '@material-ui/icons/Person'

import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    sideLinks: {
        textDecoration: 'none',
        color: 'unset',
    }
}));

const SideNav = () => {
    const [settingToggle, setSettingToggle] = useState(false)
    const theme = useTheme()
    const classes = useStyles()

    const color = (theme.palette.type === 'light') ? "primary" : ""

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            <Link to="/dashboard" className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Dashboard"><HomeIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
            </Link>

            <Link to='/dashboard/app-users' className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Upload"><PublishIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="Upload" />
                </ListItem>
            </Link>

            <Link to='/dashboard/vendors' className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Data"><StorageIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="Data" />
                </ListItem>
            </Link>

            <Link to='/dashboard/categories' className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="News"><BookIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="News" />
                </ListItem>
            </Link>


            <ListItem button onClick={() => setSettingToggle(!settingToggle)}>
                <ListItemIcon>
                    <Tooltip title="Admin"><SettingsIcon color={color} /></Tooltip>
                </ListItemIcon>
                <ListItemText primary="Admin" />
                {settingToggle ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={settingToggle} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to='/dashboard/settings/users' className={classes.sideLinks}>
                        <ListItem button className={classes.nested}>
                            <ListItemIcon>
                                <Tooltip title="Users"><SupervisorAccountIcon color={color} /></Tooltip>
                            </ListItemIcon>
                            <ListItemText primary="Users" />
                        </ListItem>
                    </Link>
                    <Link to='/dashboard/settings/roles' className={classes.sideLinks}>
                        <ListItem button className={classes.nested}>
                            <ListItemIcon>
                                <Tooltip title="Roles"><ListIcon color={color} /></Tooltip>
                            </ListItemIcon>
                            <ListItemText primary="Roles" />
                        </ListItem>
                    </Link>
                    {/* <Link to='/dashboard/profile' className={classes.sideLinks}>
                        <ListItem button className={classes.nested}>
                            <ListItemIcon>
                                <Tooltip title="My Profile"><PersonIcon color={color} /></Tooltip>
                            </ListItemIcon>
                            <ListItemText primary="My Profile" />
                        </ListItem>
                    </Link> */}
                </List>
            </Collapse>

            {/* <Link to='/dashboard/approved-stores' className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Approved Stores"><PlaylistAddCheckIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="Approved Stores" />
                </ListItem>
            </Link>

            <Link to='/dashboard/list-pending-koopan' className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="List Pending Koopan"><LocalOfferIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="List Pending Koopan" />
                </ListItem>
            </Link>

            <Link to='/dashboard/approved-koopan' className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Approved Koopan"><ConfirmationNumberIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="Approved Koopan" />
                </ListItem>
            </Link>

            <Link to='/dashboard/subscription' className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Subscription Packages"><SubscriptionsIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="Subscription Packages" />
                </ListItem>
            </Link>

            <Link to='/dashboard/states' className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="States"><ExploreIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="States" />
                </ListItem>
            </Link>

            <Link to='/dashboard/cities' className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Cities"><LocationCityIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="Cities" />
                </ListItem>
            </Link>

            <Link to='/dashboard/contact-details' className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Contact Details"><ContactPhoneIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="Contact Details" />
                </ListItem>
            </Link>

            <Link to='/dashboard/contents' className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Contents"><SubjectIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="Contents" />
                </ListItem>
            </Link>

            <Link to='/dashboard/reports' className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Reports"><AssessmentIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="Reports" />
                </ListItem>
            </Link> */}
        </List>
    )
}

export default SideNav
