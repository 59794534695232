import React, { useRef, useState, useEffect } from 'react'
import { URL, TMCE_API_KEY } from '../../config'
import { Editor } from '@tinymce/tinymce-react';
import {
    Button,
    Grid,
    Typography,
    LinearProgress,
    Snackbar,
} from '@material-ui/core';
import axios from 'axios'
import { Alert } from '@material-ui/lab';

const PrivacyPolicy = () => {
    const editorRef = useRef(null);
    const token = localStorage.getItem('kpToken')
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    const [disabled, setDisabled] = useState(false);
    // Alert
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const handleAlertClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }
    // Form data
    const [content, setContent] = useState('<p>Write content here...</p>')
    const handleEditorChange = (content) => {
        setContent(content);
    }

    // Get Content
    // const getContent = () => {
    //     setLoading(true)
    //     const headerConfig = {
    //         headers: {
    //             'accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${token}`
    //         }
    //     }
    //     const url = URL + '/API_HERE'
    //     axios.get(url, headerConfig).then(response => {
    //         setContent(response.data)
    //         setLoading(false)
    //     }).catch(error => {
    //         let message = 'Something went wrong, Please try again later'
    //         if (error.response) {
    //             message = error.response.data.detail
    //         } else if (error.request) {
    //             console.log(error.request)
    //         } else {
    //             console.log(error)
    //         }
    //         setAlertType('error')
    //         setAlertMessage(message)
    //         setShowAlert(true)
    //         setLoading(false)
    //     })
    // };

    // useEffect(() => {
    //     getContent()
    //     // eslint-disable-next-line
    // }, [])

    // Form Submit
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        setDisabled(true)
        const data = {
            content: content
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + "/API_URL"
        axios.post(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Saved Successfully')
            setShowAlert(true)
            setLoading(false)
            setDisabled(false)
        }).catch(error => {
            let message = 'Something went wrong, Please try again later'
            if (error.response) {
                message = error.response.data.detail
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setDisabled(false)
            setLoading(false)
        })
    }
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} variant="filled" severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <form onSubmit={handleFormSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <Typography variant="h6" gutterBottom>
                            Privacy Policy
                        </Typography>
                    </Grid>
                    <Grid item xs={4} className="text-right">
                        <Button variant="contained" type="submit" color="primary" disabled={disabled}>Save Changes</Button>
                    </Grid>
                </Grid>
                <br />
                {progress}
                <input id="my-file" type="file" name="my-file" style={{ display: "none" }} onChange="" />
                <Editor
                    apiKey={TMCE_API_KEY}
                    value={content}
                    onInit={(evt, editor) => editorRef.current = editor}
                    content="<p>This is the initial content of the editor</p>"
                    init={{
                        height: 700,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace wordcount visualblocks code fullscreen',
                            'insertdatetime media table contextmenu paste code'
                        ],
                        toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                        content_css: '//www.tinymce.com/css/codepen.min.css',
                        file_browser_callback_types: 'image',
                        file_picker_callback: function (callback, value, meta) {
                            if (meta.filetype === 'image') {
                                var input = document.getElementById('my-file');
                                input.click();
                                input.onchange = function () {
                                    var file = input.files[0];
                                    var reader = new FileReader();
                                    reader.onload = function (e) {
                                        console.log('name', e.target.result);
                                        callback(e.target.result, {
                                            alt: file.name
                                        });
                                    };
                                    reader.readAsDataURL(file);
                                };
                            }
                        },
                        paste_data_images: true,
                    }}
                    onEditorChange={handleEditorChange}
                />
            </form>
        </div>
    )
}

export default PrivacyPolicy
