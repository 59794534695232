import moment from "moment";
import ltrim from "validator/lib/ltrim";
import rtrim from "validator/lib/rtrim";

export function timestampToDateString(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
}

export function timestampToDateStringMoment(timestamp) {
    const formatted = moment(timestamp).format('DD/MM/YYYY');
    return formatted;
}

export function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export function trim(value) {
    value = ltrim(value)
    value = rtrim(value)
    return value
}
