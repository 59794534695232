import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import LogoDark from '../assets/images/logo/logo-dark.png'
import LogoLight from '../assets/images/logo/logo-light.png'

import {
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Toolbar,
    Tooltip
} from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import Brightness7Icon from '@material-ui/icons/Brightness7'

import { Route, Switch } from 'react-router-dom'

import ProfileMenu from './ProfileMenu'
import SideNav from './SideNav'
import Dashboard from '../pages/Dashboard'
import Unauthorized from '../pages/authentication/Unauthorized'

import ListVendors from '../pages/vendors/ListVendors'
import AddVender from '../pages/vendors/AddVendor'
import ViewVendor from '../pages/vendors/ViewVendor'
import UpdateVendor from '../pages/vendors/UpdateVendor'

import ListCategories from '../pages/categories/ListCategories'
import AddCategory from '../pages/categories/AddCategory'
import UpdateCategory from '../pages/categories/UpdateCategory'

import ListPendingStores from '../pages/stores/ListPendingStores'
import ListApprovedStores from '../pages/stores/ListApprovedStores'
import UpdateStore from '../pages/stores/UpdateStore'

import ListPendingKoopan from '../pages/koopan/ListPendingKoopan'
import ListApprovedKoopan from '../pages/koopan/ListApprovedKoopan'
import UpdateKoopan from '../pages/koopan/UpdateKoopan'

import ListSubscription from '../pages/subscription/ListSubscription'
import AddSubscription from '../pages/subscription/AddSubscription'
import UpdateSubscription from '../pages/subscription/UpdateSubscription'

import States from '../pages/States'
import Cities from '../pages/Cities'

import ContactDetails from '../pages/ContactDetails'
import Contents from '../pages/Contents'
import Reports from '../pages/Reports'

import ListAppUsers from '../pages/appUsers/ListAppUsers'

// Settings: Users
import ListUsers from '../pages/settings/users/ListUsers'
import AddUser from '../pages/settings/users/AddUser'
import UpdateUser from '../pages/settings/users/UpdateUser'
import ResetPassword from '../pages/settings/users/ResetPassword'
// Settings: Roles
import ListRoles from '../pages/settings/roles/ListRoles'
import AddRole from '../pages/settings/roles/AddRole'
import UpdateRole from '../pages/settings/roles/UpdateRole'
// Settings: Profile
import Profile from '../pages/settings/users/profile/Profile'
import EditProfile from '../pages/settings/users/profile/EditProfile'

import { useSnackbar } from 'notistack'

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    }
}))

const Layout = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('kpToken')
    const classes = useStyles()
    const [open, setOpen] = useState(true)

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    useEffect(() => {
        if (token === null) {
            const message = 'Token expired.'
            sendNotification(message, 'error')
            var location = '/login'
            props.history.push(location)
        }
        // eslint-disable-next-line
    }, [])

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const lightButton = (props.darkState) ? (<Tooltip title="Light"><Brightness7Icon style={{ color: "white" }} /></Tooltip>) : (<Tooltip title="Dark"><Brightness4Icon style={{ color: "white" }} /></Tooltip>)

    const logo = (props.darkState) ? LogoLight : LogoDark

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Grid container spacing={3}>
                        <Grid item xs={11}></Grid>
                    </Grid>
                    <IconButton onClick={props.handleThemeChange}>
                        {lightButton}
                    </IconButton>
                    <ProfileMenu />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <h1>&nbsp;&nbsp;&nbsp;Trading Theta</h1>
                <Divider />
                <SideNav />
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/dashboard/unauthorized" component={Unauthorized} />

                    <Route exact path="/dashboard/app-users" component={ListAppUsers} />

                    <Route exact path="/dashboard/vendors" component={ListVendors} />
                    <Route exact path="/dashboard/vendors/add" component={AddVender} />
                    <Route exact path="/dashboard/vendors/:id/view" component={ViewVendor} />
                    <Route exact path="/dashboard/vendors/:id" component={UpdateVendor} />

                    <Route exact path="/dashboard/categories/" component={ListCategories} />
                    <Route exact path="/dashboard/categories/add" component={AddCategory} />
                    <Route exact path="/dashboard/categories/:id" component={UpdateCategory} />

                    <Route exact path="/dashboard/list-pending-stores" component={ListPendingStores} />
                    <Route exact path="/dashboard/list-pending-stores/:id" component={UpdateStore} />
                    <Route exact path="/dashboard/approved-stores" component={ListApprovedStores} />
                    <Route exact path="/dashboard/approved-stores/:id" component={UpdateStore} />

                    <Route exact path="/dashboard/list-pending-koopan" component={ListPendingKoopan} />
                    <Route exact path="/dashboard/list-pending-koopan/:id" component={UpdateKoopan} />
                    <Route exact path="/dashboard/approved-koopan" component={ListApprovedKoopan} />
                    <Route exact path="/dashboard/approved-koopan/:id" component={UpdateKoopan} />

                    <Route exact path="/dashboard/subscription/" component={ListSubscription} />
                    <Route exact path="/dashboard/subscription/add" component={AddSubscription} />
                    <Route exact path="/dashboard/subscription/:id" component={UpdateSubscription} />

                    <Route exact path="/dashboard/states" component={States} />
                    <Route exact path="/dashboard/cities" component={Cities} />

                    <Route exact path="/dashboard/contact-details" component={ContactDetails} />
                    <Route exact path="/dashboard/contents" component={Contents} />
                    <Route exact path="/dashboard/reports" component={Reports} />

                    {/* Settings: Users */}
                    <Route exact path="/dashboard/settings/users"
                        render={(props) => {
                            <ListUsers {...props} />
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/add"
                        render={(props) => {
                            <AddUser {...props} />
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/:userId"
                        render={(props) => {
                            <UpdateUser {...props} />
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/reset-password/:userId"
                        render={(props) => {
                            <ResetPassword {...props} />
                        }}
                    />
                    {/* Settings: Roles */}
                    <Route exact path="/dashboard/settings/roles"
                        render={(props) => {
                            <ListRoles {...props} />
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles/add"
                        render={(props) => {
                            <AddRole {...props} />
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles/:roleId"
                        render={(props) => {
                            <UpdateRole {...props} />
                        }}
                    />
                    {/* Settings: Profile */}
                    <Route exact path="/dashboard/profile" component={Profile} />
                    <Route exact path="/dashboard/profile/edit" component={EditProfile} />
                </Switch>
            </main>
        </div>
    )
}

export default Layout
