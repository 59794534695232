// Core
import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../config'
import { useSnackbar } from 'notistack'
import LinearProgress from '@material-ui/core/LinearProgress';

const ContactDetails = () => {
    const token = localStorage.getItem('kpToken')
    const [loading, setLoading] = useState('');
    const progress = loading ? (<LinearProgress />) : ('')
    // Alert
    const { enqueueSnackbar } = useSnackbar()
    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    // Form data
    const [mobileNumber, setMobileNumber] = useState('')
    const [whatsappNumber, setWhatsappNumber] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')

    // Get Contact Details
    // const getContactDetails = () => {
    //     setLoading(true)
    //     const headerConfig = {
    //         headers: {
    //             'accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${token}`
    //         }
    //     }
    //     const url = URL + '/API_HERE'
    //     axios.get(url, headerConfig).then(response => {
    //         setMobileNumber(response.data.mobile)
    //         setWhatsappNumber(response.data.whatsapp)
    //         setEmail(response.data.email)
    //         setAddress(response.data.address)
    //         setLoading(false)
    //     }).catch(error => {
    //         let message = 'Try after some time.'
    //         if (error.response) {
    //             if (error.response.status === 400) {
    //                 message = error.response.data.message
    //             } else if (error.response.status === 401) {
    //                 message = error.response.data.error
    //             }
    //             (process.env.NODE_ENV !== 'production') && console.log(error.response)
    //         } else if (error.request) {
    //             (process.env.NODE_ENV !== 'production') && console.log(error.request)
    //         } else {
    //             (process.env.NODE_ENV !== 'production') && console.log(error)
    //         }
    //         sendNotification(message, 'error')
    //         setLoading(false)
    //     })
    // };

    // useEffect(() => {
    //     getContactDetails()
    //     // eslint-disable-next-line
    // }, [])

    // Input Handlers
    const handleMobileNumber = (e) => {
        setMobileNumber(e.target.value)
    }
    const handleWhatsAppNumber = (e) => {
        setWhatsappNumber(e.target.value)
    }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleAddress = (e) => {
        setAddress(e.target.value)
    }

    // Form submit
    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            mobileNumber: mobileNumber,
            whatsappNumber: whatsappNumber,
            email: email,
            address: address
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/API_HERE'
        axios.post(url, data, headerConfig).then(response => {
            const message = 'Updated Successfully'
            setLoading(false)
            sendNotification(message)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 400) {
                    message = error.response.data.message
                } else if (error.response.status === 401) {
                    message = error.response.data.error
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
            sendNotification(message, 'error')
        })
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <Typography color="textPrimary">Contact Details</Typography>
            </Breadcrumbs>
            <br />
            <Card>
                {progress}
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid item xs={12} md={4} className="mt-5">
                            <TextField
                                label="Mobile Number"
                                variant="outlined"
                                onChange={handleMobileNumber}
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} className="mt-5">
                            <TextField
                                label="WhatsApp Business"
                                variant="outlined"
                                onChange={handleWhatsAppNumber}
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} className="mt-5">
                            <TextField
                                type="email"
                                label="Email Id"
                                variant="outlined"
                                onChange={handleEmail}
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} className="mt-5">
                            <TextField
                                multiline
                                rows={5}
                                label="Office Address"
                                variant="outlined"
                                onChange={handleAddress}
                                fullWidth />
                            <br /><br /><br />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Link to="/dashboard"><Button variant="contained">Cancel</Button></Link>
                            <Button variant="contained" color="primary" className="ml-2" type="submit" disabled={loading}>
                                Save Changes
                            </Button>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default ContactDetails
