import React, { useState, useEffect } from 'react'
import {
    Avatar,
    Breadcrumbs,
    ButtonGroup, Button, IconButton,
    Tooltip,
    Grid,
    Link as MLink,
    LinearProgress,
    Typography,
    Chip
} from '@material-ui/core'
import axios from 'axios'
import { URL } from '../../config'
import { timestampToDateString, timestampToDateStringMoment } from '../../functions'
import EditIcon from '@material-ui/icons/Edit';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import StarIcon from '@material-ui/icons/Star';
import { useSnackbar } from 'notistack'
import { Link } from 'react-router-dom'

import TableLocal from '../../components/TableLocal'

const ListPendingKoopan = () => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('kpToken')
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    const [updateModel, setUpdateModel] = useState(false);
    const [tableData, setTableData] = useState([])

    const [koopanDetails, setKoopanDetails] = useState({})

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    const columns = [
        {
            name: "offerName",
            label: "Coupon Name",
            options: {
                sort: true
            }
        },
        {
            name: "vendorStoreName",
            label: "Store Details",
            options: {
                sort: true,
            }
        },
        {
            name: "categoryName",
            label: "Coupon Category",
            options: {
                sort: true,
            }
        },
        {
            name: "koopanStartdate",
            label: "Coupon Start Date",
            options: {
                sort: true,
                customBodyRender: timestampToDateStringMoment
            }
        },
        {
            name: "koopanEnddate",
            label: "Coupon End Date",
            options: {
                sort: true,
                customBodyRender: timestampToDateStringMoment
            }
        },
        {
            name: "createdAt",
            label: "Requested On",
            options: {
                sort: true,
                customBodyRender: timestampToDateString
            }
        },
        {
            name: "bannerName",
            label: "Display Image",
            options: {
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getPendingKoopan = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listAllPendingKoopan'
        axios.get(url, headerConfig).then(response => {
            response.data.map(item => {
                const action = (
                    <ButtonGroup size="small" aria-label="small outlined button group">
                        <Tooltip title="Update Status">
                            <IconButton onClick={() => handleUpdateStatus(item.id)}>
                                <VerifiedUserIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Link to={'/dashboard/list-pending-koopan/' + item.id}>
                            <Tooltip title="Edit Koopan">
                                <IconButton>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </ButtonGroup>
                )
                item.action = action

                const bannerName = (
                    <Avatar src={URL + '/downloadFile/' + item.bannerName} alt="logo" className="ml-auto mr-auto" />
                )
                item.bannerName = bannerName

                return true
            })
            setTableData(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getPendingKoopan()
        // eslint-disable-next-line
    }, [])

    const handleUpdateStatus = (updateId) => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/vendorKoopan/' + updateId
        axios.get(url, headerConfig).then(response => {
            setKoopanDetails(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
        })
        setUpdateModel(true);
    };

    const handleModalClose = () => {
        setUpdateModel(false);
    };

    // Approve Request
    const handleApproveRequest = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const data = {
            "createdBy": koopanDetails.createdBy,
            "updatedBy": koopanDetails.updatedBy,
            "id": koopanDetails.id,
            "offerName": koopanDetails.offerName,
            "actualPrice": koopanDetails.actualPrice,
            "offerPrice": koopanDetails.offerPrice,
            "categoryId": koopanDetails.categoryId,
            "koopanStartdate": koopanDetails.koopanStartdate,
            "koopanEnddate": koopanDetails.koopanEnddate,
            "vendorStoreId": koopanDetails.vendorStoreId,
            "description": koopanDetails.description,
            "koopanUniqueCode": koopanDetails.koopanUniqueCode,
            "bannerId": koopanDetails.bannerId,
            "bannerName": koopanDetails.bannerName,
            "koopanStatus": "APPROVED"
        }
        const url = URL + '/updateVendorKoopanStatus/'
        axios.post(url, data, headerConfig).then(response => {
            sendNotification('Koopan Request Approved')
            handleModalClose()
            getPendingKoopan()
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
        })
    }

    // Delete Request
    const handleDeleteRequest = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const data = {
            "createdBy": koopanDetails.createdBy,
            "updatedBy": koopanDetails.updatedBy,
            "id": koopanDetails.id,
            "offerName": koopanDetails.offerName,
            "actualPrice": koopanDetails.actualPrice,
            "offerPrice": koopanDetails.offerPrice,
            "categoryId": koopanDetails.categoryId,
            "koopanStartdate": koopanDetails.koopanStartdate,
            "koopanEnddate": koopanDetails.koopanEnddate,
            "vendorStoreId": koopanDetails.vendorStoreId,
            "description": koopanDetails.description,
            "koopanUniqueCode": koopanDetails.koopanUniqueCode,
            "bannerId": koopanDetails.bannerId,
            "bannerName": koopanDetails.bannerName,
            "koopanStatus": "DENIED"
        }
        const url = URL + '/updateVendorKoopanStatus/'
        axios.post(url, data, headerConfig).then(response => {
            let message = 'Koopan Request Denied'
            sendNotification(message)
            handleModalClose()
            getPendingKoopan()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 400) {
                    message = error.response.data.message
                } else if (error.response.status === 401) {
                    message = error.response.data.error
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
    }

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">List Pending Koopan</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            {progress}
            <TableLocal
                title={'List Pending Koopan'}
                columns={columns}
                data={tableData}
            />
            <Dialog fullWidth maxWidth="lg" onClose={handleModalClose} aria-labelledby="customized-dialog-title" open={updateModel}>
                <DialogTitle id="customized-dialog-title" onClose={handleModalClose}>
                    {koopanDetails.offerName}
                </DialogTitle>
                <DialogContent dividers>
                    <table className="details">
                        <tr>
                            <th>ID</th>
                            <td>{koopanDetails.id}</td>
                            <th>Banner</th>
                            <td><Avatar src={URL + '/downloadFile/' + koopanDetails.bannerName} alt="profile" /></td>
                        </tr>
                        <tr>
                            <th>Actual Price</th>
                            <td>{koopanDetails.actualPrice}</td>
                            <th>Offer Price</th>
                            <td>{koopanDetails.offerPrice}</td>
                        </tr>
                        <tr>
                            <th>Koopan Start Date</th>
                            <td>{timestampToDateStringMoment(koopanDetails.koopanStartdate)}</td>
                            <th>Koopan End Date</th>
                            <td>{timestampToDateStringMoment(koopanDetails.koopanEnddate)}</td>
                        </tr>
                        <tr>
                            <th>Vendor Store Name</th>
                            <td>{koopanDetails.vendorStoreName}</td>
                            <th>Description</th>
                            <td>{koopanDetails.description}</td>
                        </tr>
                        <tr>
                            <th>Category</th>
                            <td>{koopanDetails.categoryName}</td>
                            <th>Koopan Status</th>
                            <td><Chip label={koopanDetails.koopanStatus} /></td>
                        </tr>
                        <tr>
                            <th>Rating</th>
                            <td>{Array.apply(null, { length: koopanDetails.rating }).map((e, i) => (<StarIcon />))}</td>
                            <th>Hot</th>
                            <td>{koopanDetails.hot ? <Chip label="True" /> : <Chip label="False" />}</td>
                        </tr>
                        <tr>
                            <th>Created At</th>
                            <td>{timestampToDateString(koopanDetails.createdAt)}</td>
                            <th>Updated At</th>
                            <td>{timestampToDateString(koopanDetails.updatedAt)}</td>
                        </tr>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleApproveRequest} variant="contained" className="btn-approve">
                        Approve Request
                    </Button>
                    <Button onClick={handleDeleteRequest} variant="contained" className="ml-2 mr-2 btn-delete">
                        Deny Request
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ListPendingKoopan
