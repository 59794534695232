import React, { useEffect, useState, useCallback } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    Link as MLink,
    TextField,
    LinearProgress,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import { useSnackbar } from 'notistack'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slider } from '@material-ui/core'
// Cropper
import Cropper from 'react-easy-crop'
import { v4 as uuidv4 } from 'uuid';
import { DropzoneArea } from 'material-ui-dropzone'
import { getCroppedImg, getRotatedImage } from '../../canvasUtils'
import { getOrientation } from 'get-orientation/browser'
import { dataURLtoFile } from '../../functions';
import { makeStyles } from '@material-ui/core/styles';

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

const useStyles = makeStyles((theme) => ({
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
            height: 400,
        },
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    controls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    sliderLabel: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 65,
        },
    },
    slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },
    },
    paper: { minWidth: "1000px" },
}));

const UpdateVendor = (props) => {
    const classes = useStyles();
    const { id } = props.match.params
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('kpToken')
    const _user = JSON.parse(localStorage.getItem('kpUser'))
    const user = (_user === null) ? {} : _user
    const [loading, setLoading] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [cities, setCities] = useState([])
    const [fullName, setFullName] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [city, setCity] = useState(null)
    const [allData, setAllData] = useState({})
    const [isMediaUpdated, setIsMediaUpdated] = useState(false)
    const [featuredImage, setFeaturedImage] = useState('')
    const progress = loading ? (<LinearProgress />) : ('')

    // Cropper
    const [fileOpen, setFileOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [uploadImage, setUploadImage] = useState(null)
    const [mainImage, setMainImage] = useState('')

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    const getCities = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listCity'
        axios.get(url, headerConfig).then(response => {
            const cities = response.data
            setCities(cities)
            getVendorDetails(cities)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.error
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
    }

    const getVendorDetails = (cities) => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/vendorUser/' + id
        axios.get(url, headerConfig).then(res => {
            setFullName(res.data.fullName)
            setMobile(res.data.mobile)
            setEmail(res.data.email)
            setAllData(res.data)
            setFeaturedImage(res.data.imageName)
            setCity(cities.find(city => city.id === res.data.cityId))
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getCities()
        // eslint-disable-next-line
    }, [])

    const handleFullNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setFullName(value)
    }

    const handleMobileChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            setMobile(value)
        }
    }

    const handleEmailChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setEmail(value)
    }

    const handleCityChange = (e, city) => {
        setCity(city)
    }

    // Cropping Process
    const handleSelectFileOpen = (itemId) => {
        setFileOpen(true)
    }
    const handleSelectFileClose = () => {
        setFileOpen(false)
        setImageSrc(null)
    }
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])
    const imageChange = async (files) => {
        if (files && files.length > 0) {
            const file = files[0]
            let imageDataUrl = await readFile(file)
            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }
            setImageSrc(imageDataUrl)
        } else {
            setImageSrc(null)
        }
    }
    const updateImage = async () => {
        setLoading(true)
        setDisabled(true)
        const croppedImageBase64 = await getCroppedImg(
            imageSrc,
            croppedAreaPixels
        )
        const name = uuidv4() + '.png'
        var file = dataURLtoFile(croppedImageBase64, name);
        setUploadImage(file)
        setMainImage(croppedImageBase64)
        setIsMediaUpdated(true)
        handleSelectFileClose()
        setDisabled(false)
        setLoading('')
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (city === null) {
            sendNotification('Please select city.', 'error')
            setLoading(false)
        } else {
            const data = {
                createdBy: user.userId,
                updatedBy: user.userId,
                id: id,
                fullName: fullName,
                mobile: mobile,
                email: email,
                cityId: city.id,
                uuid: allData.uuid,
                imageId: allData.imageId,
                imageName: allData.imageName,
                isDeleted: allData.isDeleted,
                deviceToken: allData.deviceToken,
                deviceType: allData.deviceType,
                isVerified: allData.isVerified
            }

            if (isMediaUpdated) {
                var bodyFormData = new FormData();
                bodyFormData.append('file', uploadImage);
                const url = URL + '/uploadFile'
                await axios.post(url, bodyFormData).then(response => {
                    data.packageLogoId = response.data.id
                }).catch(error => {
                    console.log(error);
                })
            }

            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            const url = URL + '/updateVendorUser/'
            axios.post(url, data, headerConfig).then(response => {
                const message = 'Vendor updated.'
                sendNotification(message)
                setLoading(false)
                props.history.push('/dashboard/vendors')
            }).catch(error => {
                let message = 'Try after some time.'
                if (error.response) {
                    if (error.response.status === 400) {
                        message = error.response.data.message
                    } else if (error.response.status === 401) {
                        message = error.response.data.error
                    }
                    (process.env.NODE_ENV !== 'production') && console.log(error.response)
                } else if (error.request) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.request)
                } else {
                    (process.env.NODE_ENV !== 'production') && console.log(error)
                }
                sendNotification(message, 'error')
                setLoading(false)
            })
        }
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/vendors">
                    Vendors
                </MLink>
                <Typography color="textPrimary">Update Vendor</Typography>
            </Breadcrumbs>
            <br />
            <Card>
                {progress}
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} className="mt-5">
                                        <TextField
                                            id="fName"
                                            name="fName"
                                            label="Full Name"
                                            variant="outlined"
                                            value={fullName}
                                            inputProps={{ minLength: 3, maxLength: 150 }}
                                            onChange={handleFullNameChange}
                                            fullWidth
                                            required />
                                    </Grid>
                                    <Grid item xs={12} md={6} className="mt-3">
                                        <TextField
                                            id="lName"
                                            name="lName"
                                            label="Mobile"
                                            variant="outlined"
                                            value={mobile}
                                            inputProps={{ minLength: 8, maxLength: 18 }}
                                            onChange={handleMobileChange}
                                            fullWidth
                                            required />
                                    </Grid>
                                    <Grid item xs={12} md={6} className="mt-3">
                                        <TextField
                                            id="email"
                                            name="email"
                                            label="Email"
                                            variant="outlined"
                                            value={email}
                                            inputProps={{ minLength: 5, maxLength: 50, type: 'email' }}
                                            onChange={handleEmailChange}
                                            fullWidth
                                            required />
                                    </Grid>
                                    <Grid item xs={12} md={6} className="mt-3">
                                        <Autocomplete
                                            options={cities}
                                            autoHighlight
                                            value={city}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="City"
                                                variant="outlined"
                                                required
                                            />}
                                            onChange={handleCityChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4} className="mt-5">
                                <Button variant="contained" color="primary" onClick={handleSelectFileOpen}>
                                    Change Image*
                                </Button>
                                <br /><br />
                                {isMediaUpdated ? (<img src={mainImage} alt="" style={{ height: "100px" }} />) : (<img src={URL + '/downloadFile/' + featuredImage} alt="" style={{ height: "100px" }} />)}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} className="mt-5">
                            <Link to="/dashboard/vendors"><Button variant="contained">Cancel</Button></Link>
                            <Button variant="contained" color="primary" className="ml-2" type="submit">
                                Update
                            </Button>
                        </Grid>
                    </form>
                    <Dialog
                        classes={{ paper: classes.paper }}
                        open={fileOpen}
                        onClose={handleSelectFileClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        {loading}
                        <DialogTitle id="alert-dialog-title">Upload Image</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {imageSrc ? (
                                    <div>
                                        <div className={classes.cropContainer}>
                                            <Cropper
                                                image={imageSrc}
                                                crop={crop}
                                                zoom={zoom}
                                                aspect={1 / 1}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                            />
                                        </div>
                                        <div className={classes.controls}>
                                            <div className={classes.sliderContainer}>
                                                <Typography
                                                    variant="overline"
                                                    classes={{ root: classes.sliderLabel }}
                                                >
                                                    Zoom
                                                </Typography>
                                                <Slider
                                                    value={zoom}
                                                    min={1}
                                                    max={3}
                                                    step={0.1}
                                                    aria-labelledby="Zoom"
                                                    classes={{ root: classes.slider }}
                                                    onChange={(e, zoom) => setZoom(zoom)}
                                                />
                                            </div>
                                            <Button onClick={handleSelectFileClose} variant="contained" autoFocus>
                                                Close
                                            </Button>
                                            <Button variant="contained" color="warning" className="ml-2" onClick={updateImage} disabled={disabled}>
                                                Update
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <DropzoneArea
                                        filesLimit={1}
                                        dropzoneText="Drag and drop an image here or click"
                                        showAlerts={['error']}
                                        acceptedFiles={['image/*']}
                                        onChange={imageChange}
                                    />
                                )}
                            </DialogContentText>
                        </DialogContent>
                        {imageSrc ? ('') : (
                            <DialogActions>
                                <Button onClick={handleSelectFileClose} variant="contained" autoFocus>
                                    Close
                                </Button>
                                <Button variant="contained" color="warning" className="ml-2">
                                    Update
                                </Button>
                            </DialogActions>)}
                    </Dialog>
                </CardContent>
            </Card>
        </div>
    )
}

export default UpdateVendor
