import { Avatar, Card, CardContent, Grid, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Line } from 'react-chartjs-2';
import PeopleIcon from '@material-ui/icons/People';
import StorefrontIcon from '@material-ui/icons/Storefront';
import CategoryIcon from '@material-ui/icons/Category';
import StoreIcon from '@material-ui/icons/Store';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
    createStyles({
        large: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#17A2B8"
        },
        cold: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#f1a649"
        },
        loss: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#ef6860"
        },
        hot: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#e1cd42"
        },
        soft: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#429ce1"
        },
        warm: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#6368c0"
        },
        received: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#2fad3b"
        },
        pending: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#17b893"
        },
        transparent: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "transparent"
        },
        largeIcon: {
            fontSize: 50
        },
        cardLink: {
            textDecoration: "None"
        }
    }),
);

const Dashboard = (props) => {
    const classes = useStyles()
    const theme = useTheme();
    const [fontColor, setFontColor] = useState('black')
    const options = {
        responsive: true,
        legend: {
            labels: {
                fontColor: fontColor
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    fontColor: fontColor
                }
            }],
            xAxes: [{
                ticks: {
                    fontColor: fontColor
                }
            }]
        }
    }
    useEffect(() => {
        const type = theme.palette.type
        const color = (type === 'dark') ? 'white' : 'black'
        setFontColor(color)
        // eslint-disable-next-line
    }, [])
    // eslint-disable-next-line
    const [chartData, setChartData] = useState({
        redeemedCoupons: {
            labels: ['5 Oct', '10 Oct', '15 Oct', '20 Oct', '25 Oct', '30 Oct'],
            data: [5, 10, 15, 7, 25, 30],
        },
        grossRevenueOfCoupons: {
            labels: ['5 Oct', '10 Oct', '15 Oct', '20 Oct', '25 Oct', '30 Oct'],
            data: [500, 1500, 1000, 1500, 2500, 2200],
        },
        subscriptionDetails: {
            labels: ['5 Oct', '10 Oct', '15 Oct', '20 Oct', '25 Oct', '30 Oct'],
            data: [150, 200, 180, 170, 250, 230],
        },
        subscriptionRevenue: {
            labels: ['5 Oct', '10 Oct', '15 Oct', '20 Oct', '25 Oct', '30 Oct'],
            data: [750, 1000, 500, 1200, 600, 1500],
        },
        silver: {
            labels: ['5 Oct', '10 Oct', '15 Oct', '20 Oct', '25 Oct', '30 Oct'],
            data: [1300, 800, 850, 750, 500, 1800],
        }
    })
    // redeemedCoupons
    const dataRC = {
        labels: chartData.redeemedCoupons.labels,
        datasets: [
            {
                label: 'Redeemed Coupons',
                data: chartData.redeemedCoupons.data,
                fill: false,
                backgroundColor: 'rgb(0,180,242)',
                borderColor: 'rgba(0,180,242)',
            },
        ],
    };
    // grossRevenueOfCoupons
    const dataGRC = {
        labels: chartData.grossRevenueOfCoupons.labels,
        datasets: [
            {
                label: 'Gross Revenue of Coupons',
                data: chartData.grossRevenueOfCoupons.data,
                fill: false,
                backgroundColor: 'rgb(252, 3, 161)',
                borderColor: 'rgba(252, 3, 161)',
            },
        ],
    };
    // subscriptionDetails
    const dataSD = {
        labels: chartData.subscriptionDetails.labels,
        datasets: [
            {
                label: 'Silver',
                data: chartData.silver.data,
                fill: false,
                backgroundColor: 'rgb(87, 210, 225)',
                borderColor: 'rgba(87, 210, 225)',
            },
            {
                label: 'Platinum',
                data: chartData.subscriptionRevenue.data,
                fill: false,
                backgroundColor: 'rgb(41, 227, 20)',
                borderColor: 'rgba(41, 227, 20)',
            },
            {
                label: 'Gold',
                data: chartData.grossRevenueOfCoupons.data,
                fill: false,
                backgroundColor: 'rgb(243, 90, 151)',
                borderColor: 'rgba(243, 90, 151)',
            },
            {
                label: 'Diamond',
                data: chartData.subscriptionDetails.data,
                fill: false,
                backgroundColor: 'rgb(239, 127, 26)',
                borderColor: 'rgba(239, 127, 26)',
            },
        ],
    };
    // subscriptionRevenue
    const dataSR = {
        labels: chartData.subscriptionRevenue.labels,
        datasets: [
            {
                label: 'Silver',
                data: chartData.subscriptionDetails.data,
                fill: false,
                backgroundColor: 'rgb(87, 210, 225)',
                borderColor: 'rgba(87, 210, 225)',
            },
            {
                label: 'Platinum',
                data: chartData.subscriptionRevenue.data,
                fill: false,
                backgroundColor: 'rgb(41, 227, 20)',
                borderColor: 'rgba(41, 227, 20)',
            },
            {
                label: 'Gold',
                data: chartData.grossRevenueOfCoupons.data,
                fill: false,
                backgroundColor: 'rgb(243, 90, 151)',
                borderColor: 'rgba(243, 90, 151)',
            },
            {
                label: 'Diamond',
                data: chartData.silver.data,
                fill: false,
                backgroundColor: 'rgb(239, 127, 26)',
                borderColor: 'rgba(239, 127, 26)',
            },
        ],
    };
    return (
        <div>
            <Grid container spacing="3">
                <Grid item xs={12} lg={3}>
                    <Link to='dashboard' className={classes.cardLink}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Avatar variant="rounded" className={classes.large}>
                                            <PeopleIcon className={classes.largeIcon} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography color="textSecondary" gutterBottom variant="h6">Total Admins</Typography>
                                        <Typography color="textPrimary" variant="h6">3</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Link to='dashboard/vendors' className={classes.cardLink}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Avatar variant="rounded" className={classes.hot}>
                                            <StorefrontIcon className={classes.largeIcon} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography color="textSecondary" gutterBottom variant="h6">Total Registered Vendors</Typography>
                                        <Typography color="textPrimary" variant="h6">126</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Link to='dashboard/categories' className={classes.cardLink}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Avatar variant="rounded" className={classes.warm}>
                                            <CategoryIcon className={classes.largeIcon} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography color="textSecondary" gutterBottom variant="h6">Total Categories</Typography>
                                        <Typography color="textPrimary" variant="h6">46</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Link to='dashboard' className={classes.cardLink}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Avatar variant="rounded" className={classes.cold}>
                                            <StoreIcon className={classes.largeIcon} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography color="textSecondary" gutterBottom variant="h6">Total Registered Stores</Typography>
                                        <Typography color="textPrimary" variant="h6">80</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing="3">
                <Grid item xs={12} lg={6}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        Redeemed Coupons: <b>548</b>
                    </Typography>
                    <Card>
                        <CardContent>
                            <Line data={dataRC} options={options} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        Gross Revenue of Coupons: <b>₹ 5,68,42,185</b>
                    </Typography>
                    <Card>
                        <CardContent>
                            <Line data={dataGRC} options={options} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        Subscription Details: <b>246</b>
                    </Typography>
                    <Card>
                        <CardContent>
                            <Line data={dataSD} options={options} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        Subscription Revenue: <b>₹ 5,68,42,185</b>
                    </Typography>
                    <Card>
                        <CardContent>
                            <Line data={dataSR} options={options} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        Commission Earned on Coupon: <b>₹ 5,68,42,185</b>
                    </Typography>
                    <Card>
                        <CardContent>
                            <Line data={dataRC} options={options} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default Dashboard
