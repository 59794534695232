import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    ButtonGroup, Button, IconButton,
    Grid,
    Link as MLink,
    LinearProgress,
    Tooltip,
    Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../config'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack'

import { timestampToDateString } from '../../functions'

import TableLocal from '../../components/TableLocal'

const ListVendors = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('kpToken')
    const _user = JSON.parse(localStorage.getItem('kpUser'))
    const user = (_user === null) ? {} : _user
    const [tableData, setTableData] = useState([])
    const [deleteModel, setDeleteModel] = useState(false);
    const [alldata, setAlldata] = useState({})

    const [loading, setLoading] = useState(false)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return rowIndex + 1
                }
            }
        },
        {
            name: "fullName",
            label: "Full Name",
            options: {
                sort: true,
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                sort: true,
            }
        },
        {
            name: "uuid",
            label: "UUID",
            options: {
                sort: true,
            }
        },
        {
            name: "createdAt",
            label: "Date",
            options: {
                sort: true,
                customBodyRender: timestampToDateString
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }


    // Delete Vendor
    const deleteItem = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const data = {
            createdBy: alldata.createdBy,
            updatedBy: user.userId,
            id: alldata.id,
            fullName: alldata.fullName,
            mobile: alldata.mobile,
            email: alldata.email,
            uuid: alldata.uuid,
            imageId: alldata.imageId,
            imageName: alldata.imageName,
            isDeleted: true,
            cityId: alldata.cityId,
            deviceToken: alldata.deviceToken,
            deviceType: alldata.deviceType,
            isVerified: alldata.isVerified
        }
        const url = URL + '/updateVendorUser/'
        axios.post(url, data, headerConfig).then(response => {
            let message = 'Deleted successfully'
            sendNotification(message)
            handleModalClose()
            getList()
        }).catch(error => {
            console.log(error);
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 400) {
                    message = error.response.data.message
                } else if (error.response.status === 401) {
                    message = error.response.data.error
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
        getList()
        setDeleteModel(false);
    }

    const handleModelOpen = (deleteId) => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/vendorUser/' + deleteId
        axios.get(url, headerConfig).then(res => {
            setAlldata(res.data)
            // setCity(res.data.cityId)
        }).catch(err => {
            console.log(err)
        })
        setDeleteModel(true);
    };

    const handleModalClose = () => {
        // alldata({})
        setDeleteModel(false);
    };

    const getList = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listVendorUser'
        axios.get(url, headerConfig).then(response => {
            response.data.map(item => {
                const action = (
                    <ButtonGroup size="small" aria-label="small outlined button group">
                        <Tooltip title="View">
                            <IconButton onClick={() => props.history.push('/dashboard/vendors/' + item.id + '/view')}>
                                <VisibilityIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Update">
                            <IconButton onClick={() => props.history.push('/dashboard/vendors/' + item.id)}>
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton onClick={() => handleModelOpen(item.id)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                )
                item.action = action
                item.uuid = <MLink href={'/#/dashboard/vendors/' + item.id + '/view'}>{item.uuid}</MLink>
                return true
            })
            setTableData(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Vendors</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item >
                    <Link to="vendors/add"><Button variant="contained" color="primary" startIcon={<AddIcon />}>Add Vendor</Button></Link>
                </Grid>
            </Grid>
            <br />
            {progress}
            <TableLocal
                title={'List Vendors'}
                columns={columns}
                data={tableData}
            />
            <Dialog
                open={deleteModel}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this Vendor?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteItem} color="primary" autoFocus>
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ListVendors
