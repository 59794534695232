import './App.css'
import './assets/css/style.css'
import React, { useEffect, useState } from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Layout from './components/Layout'
import Login from './pages/authentication/Login'
import { SnackbarProvider } from 'notistack'
import Slide from '@material-ui/core/Slide'


function App() {
    const [darkState, setDarkState] = useState(false)
    const palletType = darkState ? 'dark' : 'light'

    const primaryDark = '#ef7f1a'
    const primaryLight = '#393185'
    const mainPrimaryColor = darkState ? primaryDark : primaryLight

    const secondaryDark = '#FF740A'
    const secondaryLight = '#FF740A'
    const mainSecondaryColor = darkState ? secondaryDark : secondaryLight

    const darkTheme = createTheme({
        palette: {
            type: palletType,
            primary: {
                main: mainPrimaryColor
            },
            secondary: {
                main: mainSecondaryColor
            }
        }
    })

    useEffect(() => {
        const darkState = localStorage.getItem('darkState')
        if (darkState === 'light') {
            setDarkState(false)
        } else {
            setDarkState(true)
        }
        // eslint-disable-next-line
    }, [])

    const handleThemeChange = () => {
        setDarkState(!darkState)
        const palletType = !darkState ? 'dark' : 'light'
        localStorage.setItem('darkState', palletType)
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <SnackbarProvider
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            TransitionComponent={Slide} 
            maxSnack={3}>
                <div className="noSelect">
                    <Router>
                        <Route path='/login' component={Login} />
                        <Switch>
                            <Route exact path='/' render={(props) => {
                                const token = localStorage.getItem('kpToken')
                                if (token !== null) {
                                    props.history.push('/dashboard')
                                }
                                else {
                                    props.history.push('/login')
                                }
                            }} />
                            <Route
                                path='/dashboard'
                                render={(props) => (
                                    <Layout {...props} handleThemeChange={handleThemeChange} darkState={darkState} />
                                )}
                            />
                        </Switch>
                    </Router>
                </div>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default App
