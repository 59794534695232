import React, { useState, useEffect } from 'react'
import {
    Avatar,
    Breadcrumbs,
    ButtonGroup,
    IconButton,
    Grid,
    Link as MLink,
    LinearProgress,
    Tooltip,
    Typography
} from '@material-ui/core'

import axios from 'axios'
import { URL } from '../../config'
import { timestampToDateString } from '../../functions'
import EditIcon from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'

import TableLocal from '../../components/TableLocal'

const ListApprovedStores = () => {
    const token = localStorage.getItem('kpToken')
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')
    const [tableData, setTableData] = useState([])

    const columns = [
        {
            name: "storeName",
            label: "Store Details",
            options: {
                sort: true
            }
        },
        {
            name: "categoryName",
            label: "Store Category",
            options: {
                sort: true,
            }
        },
        {
            name: "storeMobile",
            label: "Mobile Number",
            options: {
                sort: true,
            }
        },
        {
            name: "storeEmailId",
            label: "Email ID",
            options: {
                sort: true,
            }
        },
        {
            name: "createdAt",
            label: "Requested On",
            options: {
                sort: true,
                customBodyRender: timestampToDateString
            }
        },
        {
            name: "logoName",
            label: "Display Image",
            options: {
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getKoopans = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listAllApprovedStore'
        axios.get(url, headerConfig).then(response => {
            response.data.map(item => {
                const action = (
                    <ButtonGroup size="small" aria-label="small outlined button group">
                        <Link to={'/dashboard/approved-stores/' + item.id}>
                            <Tooltip title="Edit">
                                <IconButton>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </ButtonGroup>
                )
                item.action = action

                const logoName = (
                    <Avatar src={URL + '/downloadFile/' + item.logoName} alt="logo" className="ml-auto mr-auto" />
                )
                item.logoName = logoName
                return true
            })
            setTableData(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getKoopans()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Approved Stores</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            {progress}
            <TableLocal
                title="Approved Stores"
                columns={columns}
                data={tableData}
            />
        </div>
    )
}

export default ListApprovedStores
