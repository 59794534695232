import React, { useEffect, useState, useCallback } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    IconButton,
    Typography
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slider } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../config'
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack'
import DeleteIcon from '@material-ui/icons/Delete';
// Cropper
import Cropper from 'react-easy-crop'
import { DropzoneArea } from 'material-ui-dropzone'
import { getCroppedImg, getRotatedImage } from '../../canvasUtils'
import { getOrientation } from 'get-orientation/browser'
import { dataURLtoFile, trim } from '../../functions';
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles';

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

const useStyles = makeStyles((theme) => ({
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
            height: 400,
        },
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    controls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    sliderLabel: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 65,
        },
    },
    slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },
    },
    paper: { minWidth: "1000px" },
}));

const UpdateStore = (props) => {
    const location = useLocation()
    const title = location.pathname.includes('approved-stores') ? 'Approved Stores' : 'List Pending Stores'
    const path = location.pathname.includes('approved-stores') ? '/dashboard/approved-stores' : '/dashboard/list-pending-stores'
    const classes = useStyles();
    const { id } = props.match.params
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState('');
    const [disabled, setDisabled] = useState(false);
    const token = localStorage.getItem('kpToken')
    const _user = JSON.parse(localStorage.getItem('kpUser'))
    const user = (_user === null) ? {} : _user
    const progress = loading ? (<LinearProgress />) : ('')

    // Form
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(null)
    const [cities, setCities] = useState([])
    const [city, setCity] = useState(null)
    const [storeDetail, setStoreDetail] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [email, setEmail] = useState('')
    const [otherImages, setOtherImages] = useState([])
    const [otherImagesSrcIds, setOtherImagesSrcIds] = useState([])

    const [featuredImage, setFeaturedImage] = useState('')
    const [isMediaUpdated, setIsMediaUpdated] = useState(false)
    const [storeData, setStoreData] = useState({})
    const [otherImgId, setOtherImgId] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);

    // Cropper
    const [fileOpen, setFileOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [uploadImage, setUploadImage] = useState(null)
    const [mainImage, setMainImage] = useState('')

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    // Cropping Process
    const handleSelectFileOpen = (itemId) => {
        setFileOpen(true)
    }
    const handleSelectFileClose = () => {
        setFileOpen(false)
        setImageSrc(null)
    }
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])
    const imageChange = async (files) => {
        if (files && files.length > 0) {
            const file = files[0]
            let imageDataUrl = await readFile(file)
            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }
            setImageSrc(imageDataUrl)
        } else {
            setImageSrc(null)
        }
    }
    const updateImage = async () => {
        setLoading(true)
        setDisabled(true)
        const croppedImageBase64 = await getCroppedImg(
            imageSrc,
            croppedAreaPixels
        )
        const name = uuidv4() + '.png'
        var file = dataURLtoFile(croppedImageBase64, name);
        setUploadImage(file)
        setMainImage(croppedImageBase64)
        setIsMediaUpdated(true)
        handleSelectFileClose()
        setDisabled(false)
        setLoading('')
    }

    const getCategories = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listCategory'
        axios.get(url, headerConfig).then(response => {
            setCategories(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const getCities = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/listCity'
        axios.get(url, headerConfig).then(response => {
            setCities(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getCategories()
        getCities()
        getData()
        // eslint-disable-next-line
    }, [])

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/vendorStore/' + id
        axios.get(url, headerConfig).then(res => {
            const data = res.data
            const category = {
                id: data.categoryId,
                categoryName: data.categoryName
            }
            setCategory(category)

            const city = {
                id: data.cityId,
                name: data.cityName
            }
            setCity(city)

            let otherImages = []
            let i = 0
            data.imageIds.map(image => {
                otherImages.push({
                    id: image,
                    name: data.imageNames[i],
                })
                i++
                return true
            })
            setOtherImages(otherImages)

            setStoreDetail(data.storeName)
            setMobileNumber(data.storeMobile)
            setEmail(data.storeEmailId)
            setFeaturedImage(data.logoName)
            setStoreData(data)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    // Delete confirmation Model
    const handleDeleteOpen = (id) => {
        setOtherImgId(id)
        setOpenDelete(true);
    };
    const handleDeleteClose = () => {
        setOtherImgId(null)
        setOpenDelete(false);
    };
    const deleteFeaturedImg = () => {
        const result = otherImages.filter(image => image.id !== otherImgId);
        setOtherImages(result)
        setOpenDelete(false);
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const data = storeData
        data.storeName = trim(storeDetail)
        data.categoryId = category.id
        data.cityId = city.id
        data.storeMobile = trim(mobileNumber)
        data.storeEmailId = trim(email)
        data.updatedBy = user.userId
        if (otherImagesSrcIds.length > 0) {
            let imageIds = []
            otherImages.map(image => {
                imageIds.push(image.id)
                return true
            })
            const finalIds = imageIds.concat(otherImagesSrcIds)
            data.imageIds = finalIds
        } else {
            let imageIds = []
            otherImages.map(image => {
                imageIds.push(image.id)
                return true
            })
            data.imageIds = imageIds
        }

        if (isMediaUpdated) {
            var bodyFormData = new FormData();
            bodyFormData.append('file', uploadImage);
            const url = URL + '/uploadFile'
            await axios.post(url, bodyFormData).then(response => {
                data.logoId = response.data.id
            }).catch(error => {
                console.log(error);
            })
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/updateVendorStore'
        axios.post(url, data, headerConfig).then(response => {
            const message = 'Vendor store updated.'
            sendNotification(message)
            setLoading(false)
            props.history.push(path)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 400) {
                    message = error.response.data.message
                } else if (error.response.status === 401) {
                    message = error.response.data.error
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
            setLoading(false)
        })
    }

    const handleCategoryChange = (e, item) => {
        setCategory(item)
    }

    const handleCityChange = (e, item) => {
        setCity(item)
    }

    const otherImagesChange = async (files) => {
        setLoading(true)
        let ids = []
        const url = URL + '/uploadFile'
        files.map(async (file) => {
            var bodyFormData = new FormData();
            bodyFormData.append('file', file);
            await axios.post(url, bodyFormData).then(response => {
                ids.push(response.data.id)
            }).catch(error => {
                console.log(error);
            })
        })
        setOtherImagesSrcIds(ids)
        setLoading(false)
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href={'/#' + path}>
                    {title}
                </MLink>
                <Typography color="textPrimary">Update Store</Typography>
            </Breadcrumbs>
            <br />
            <Dialog
                classes={{ paper: classes.paper }}
                open={fileOpen}
                onClose={handleSelectFileClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {loading}
                <DialogTitle id="alert-dialog-title">Upload Image</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {imageSrc ? (
                            <div>
                                <div className={classes.cropContainer}>
                                    <Cropper
                                        image={imageSrc}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1 / 1}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                </div>
                                <div className={classes.controls}>
                                    <div className={classes.sliderContainer}>
                                        <Typography
                                            variant="overline"
                                            classes={{ root: classes.sliderLabel }}
                                        >
                                            Zoom
                                        </Typography>
                                        <Slider
                                            value={zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aria-labelledby="Zoom"
                                            classes={{ root: classes.slider }}
                                            onChange={(e, zoom) => setZoom(zoom)}
                                        />
                                    </div>
                                    <Button onClick={handleSelectFileClose} variant="contained" autoFocus>
                                        Close
                                    </Button>
                                    <Button variant="contained" color="warning" className="ml-2" onClick={updateImage} disabled={disabled}>
                                        Update
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <DropzoneArea
                                filesLimit={1}
                                dropzoneText="Drag and drop an image here or click"
                                showAlerts={['error']}
                                acceptedFiles={['image/*']}
                                onChange={imageChange}
                            />
                        )}
                    </DialogContentText>
                </DialogContent>
                {imageSrc ? ('') : (
                    <DialogActions>
                        <Button onClick={handleSelectFileClose} variant="contained" autoFocus>
                            Close
                        </Button>
                        <Button variant="contained" color="warning" className="ml-2">
                            Update
                        </Button>
                    </DialogActions>)}
            </Dialog>
            {progress}
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Store Detail"
                                    variant="outlined"
                                    inputProps={{ minLength: 2, maxLength: 150 }}
                                    onChange={e => setStoreDetail(e.target.value)}
                                    value={storeDetail}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={categories}
                                    autoHighlight
                                    getOptionLabel={(option) => option.categoryName}
                                    value={category}
                                    renderInput={(params) => <TextField {...params} label="Category" required variant="outlined" />}
                                    onChange={handleCategoryChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={cities}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    value={city}
                                    renderInput={(params) => <TextField {...params} label="City" required variant="outlined" />}
                                    onChange={handleCityChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Mobile Number"
                                    variant="outlined"
                                    inputProps={{ minLength: 10, maxLength: 10, pattern: "[0-9]{10}", title: "Numbers only" }}
                                    onChange={e => setMobileNumber(e.target.value)}
                                    value={mobileNumber}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Email Id"
                                    variant="outlined"
                                    type="email"
                                    inputProps={{ minLength: 5, maxLength: 150 }}
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button variant="contained" color="primary" onClick={handleSelectFileOpen}>
                                    Change Featured Image*
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                {isMediaUpdated ? (<img src={mainImage} alt="" style={{ height: "100px" }} />) : (<img src={URL + '/downloadFile/' + featuredImage} alt="" style={{ height: "100px" }} />)}
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <DropzoneArea
                                    dropzoneText="Upload new images"
                                    showAlerts={['error']}
                                    acceptedFiles={['image/*']}
                                    onChange={otherImagesChange}
                                />
                            </Grid>
                            <Grid item xs={8} md={8}>
                                <Grid container>
                                    {otherImages.map((image, index) => (
                                        <Grid item md={2}>
                                            <div key={index} className="mt-3 other-image">
                                                <img src={URL + '/downloadFile/' + image.name} alt="other img" />
                                                <br />
                                                <IconButton aria-label="delete" className="btn-delete" onClick={() => handleDeleteOpen(image.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Link to={path}><Button variant="contained" disabled={loading}>Cancel</Button></Link>
                                <Button variant="contained" color="primary" className="ml-2" type="submit" disabled={loading}>
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Dialog open={openDelete} onClose={handleDeleteClose} className="form-action" aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Are you sure you want to delete?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={deleteFeaturedImg} variant="contained" color="secondary">
                                Yes, Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </CardContent>
            </Card>
        </div>
    )
}

export default UpdateStore
