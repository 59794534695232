import React, { useEffect, useState, useCallback } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    Link as MLink,
    TextField,
    LinearProgress,
    Typography
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slider } from '@material-ui/core'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../config'
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack'
// Cropper
import Cropper from 'react-easy-crop'
import { DropzoneArea } from 'material-ui-dropzone'
import { getCroppedImg, getRotatedImage } from '../../canvasUtils'
import { getOrientation } from 'get-orientation/browser'
import { dataURLtoFile, trim } from '../../functions';
import { makeStyles } from '@material-ui/core/styles';

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

const useStyles = makeStyles((theme) => ({
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
            height: 400,
        },
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    controls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    sliderLabel: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 65,
        },
    },
    slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },
    },
    paper: { minWidth: "1000px" },
}));

const UpdateSubscription = (props) => {
    const classes = useStyles();
    const { id } = props.match.params
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState('');
    const [disabled, setDisabled] = useState(false);
    const token = localStorage.getItem('kpToken')
    const _user = JSON.parse(localStorage.getItem('kpUser'))
    const user = (_user === null) ? {} : _user
    const progress = loading ? (<LinearProgress />) : ('')

    // Form
    const [name, setName] = useState('')
    const [type, setType] = useState('')
    const [price, setPrice] = useState('')
    const [days, setDays] = useState('')
    const [koopan, setKoopan] = useState('')
    const [reward, setReward] = useState('')
    const [redeem, setRedeem] = useState('')
    const [packageId, setPackageId] = useState('')

    const [featuredImage, setFeaturedImage] = useState('')
    const [isMediaUpdated, setIsMediaUpdated] = useState(false)
    const [packageData, setPackageData] = useState({})

    // Cropper
    const [fileOpen, setFileOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [uploadImage, setUploadImage] = useState(null)
    const [mainImage, setMainImage] = useState('')

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    // Cropping Process
    const handleSelectFileOpen = (itemId) => {
        setFileOpen(true)
    }
    const handleSelectFileClose = () => {
        setFileOpen(false)
        setImageSrc(null)
    }
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])
    const imageChange = async (files) => {
        if (files && files.length > 0) {
            const file = files[0]
            let imageDataUrl = await readFile(file)
            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }
            setImageSrc(imageDataUrl)
        } else {
            setImageSrc(null)
        }
    }
    const updateImage = async () => {
        setLoading(true)
        setDisabled(true)
        const croppedImageBase64 = await getCroppedImg(
            imageSrc,
            croppedAreaPixels
        )
        const name = uuidv4() + '.png'
        var file = dataURLtoFile(croppedImageBase64, name);
        setUploadImage(file)
        setMainImage(croppedImageBase64)
        setIsMediaUpdated(true)
        handleSelectFileClose()
        setDisabled(false)
        setLoading('')
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/subscriptionPackage/' + id
        axios.get(url, headerConfig).then(res => {
            setName(res.data.packageName)
            setType(res.data.packageType)
            setPrice(res.data.packagePrice)
            setDays(res.data.noOfDays)
            setKoopan(res.data.noOfKoopan)
            setReward(res.data.rewardPoint)
            setRedeem(res.data.redeemPoint)
            setFeaturedImage(res.data.packageLogoName)
            setPackageId(res.data.packageId)
            setPackageData(res.data)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            id: packageData.id,
            packageName: trim(name),
            packageLogoId: packageData.packageLogoId,
            packageType: type,
            packagePrice: parseInt(price),
            noOfDays: parseInt(days),
            noOfKoopan: parseInt(koopan),
            rewardPoint: parseInt(reward),
            redeemPoint: parseInt(redeem),
            packageId,
            createdBy: user.userId,
            updatedBy: user.userId
        }

        if (isMediaUpdated) {
            var bodyFormData = new FormData();
            bodyFormData.append('file', uploadImage);
            const url = URL + '/uploadFile'
            await axios.post(url, bodyFormData).then(response => {
                data.packageLogoId = response.data.id
            }).catch(error => {
                console.log(error);
            })
        }

        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const url = URL + '/saveSubscriptionPackage'
        axios.post(url, data, headerConfig).then(response => {
            const message = 'Subscription package updated.'
            sendNotification(message)
            setLoading(false)
            props.history.push('/dashboard/subscription')
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 400) {
                    message = error.response.data.message
                } else if (error.response.status === 401) {
                    message = error.response.data.error
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
            setLoading(false)
        })
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/subscription">
                    Subscription Packages
                </MLink>
                <Typography color="textPrimary">Update Subscription Package</Typography>
            </Breadcrumbs>
            <br />
            <Dialog
                classes={{ paper: classes.paper }}
                open={fileOpen}
                onClose={handleSelectFileClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {loading}
                <DialogTitle id="alert-dialog-title">Upload Image</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {imageSrc ? (
                            <div>
                                <div className={classes.cropContainer}>
                                    <Cropper
                                        image={imageSrc}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1 / 1}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                </div>
                                <div className={classes.controls}>
                                    <div className={classes.sliderContainer}>
                                        <Typography
                                            variant="overline"
                                            classes={{ root: classes.sliderLabel }}
                                        >
                                            Zoom
                                        </Typography>
                                        <Slider
                                            value={zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aria-labelledby="Zoom"
                                            classes={{ root: classes.slider }}
                                            onChange={(e, zoom) => setZoom(zoom)}
                                        />
                                    </div>
                                    <Button onClick={handleSelectFileClose} variant="contained" autoFocus>
                                        Close
                                    </Button>
                                    <Button variant="contained" color="warning" className="ml-2" onClick={updateImage} disabled={disabled}>
                                        Update
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <DropzoneArea
                                filesLimit={1}
                                dropzoneText="Drag and drop an image here or click"
                                showAlerts={['error']}
                                acceptedFiles={['image/*']}
                                onChange={imageChange}
                            />
                        )}
                    </DialogContentText>
                </DialogContent>
                {imageSrc ? ('') : (
                    <DialogActions>
                        <Button onClick={handleSelectFileClose} variant="contained" autoFocus>
                            Close
                        </Button>
                        <Button variant="contained" color="warning" className="ml-2">
                            Update
                        </Button>
                    </DialogActions>)}
            </Dialog>
            {progress}
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Name"
                                    variant="outlined"
                                    inputProps={{ maxLength: 150 }}
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Type"
                                    variant="outlined"
                                    inputProps={{ maxLength: 150 }}
                                    onChange={e => setType(e.target.value)}
                                    value={type}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Price"
                                    variant="outlined"
                                    type="Number"
                                    inputProps={{ maxLength: 150, min: 0 }}
                                    onChange={e => setPrice(e.target.value)}
                                    value={price}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="No of Days"
                                    variant="outlined"
                                    type="Number"
                                    inputProps={{ maxLength: 150, min: 0 }}
                                    onChange={e => setDays(e.target.value)}
                                    value={days}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="No of Koopan"
                                    variant="outlined"
                                    type="Number"
                                    inputProps={{ maxLength: 150, min: 0 }}
                                    onChange={e => setKoopan(e.target.value)}
                                    value={koopan}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Reward Point"
                                    variant="outlined"
                                    type="Number"
                                    inputProps={{ maxLength: 150, min: 0 }}
                                    onChange={e => setReward(e.target.value)}
                                    value={reward}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Redeem Point"
                                    variant="outlined"
                                    type="Number"
                                    inputProps={{ maxLength: 150, min: 0 }}
                                    onChange={e => setRedeem(e.target.value)}
                                    value={redeem}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Product Id"
                                    variant="outlined"
                                    inputProps={{ maxLength: 150 }}
                                    onChange={e => setPackageId(e.target.value)}
                                    value={packageId}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button variant="contained" color="primary" onClick={handleSelectFileOpen}>
                                    Change Featured Image*
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                {isMediaUpdated ? (<img src={mainImage} alt="" style={{ height: "100px" }} />) : (<img src={URL + '/downloadFile/' + featuredImage} alt="" style={{ height: "100px" }} />)}
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Link to="/dashboard/subscription"><Button variant="contained" disabled={loading}>Cancel</Button></Link>
                                <Button variant="contained" color="primary" className="ml-2" type="submit" disabled={loading}>
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default UpdateSubscription
